@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "./variables";

body {
  padding: 0;
  margin: 0;
  background-color:#fff;
}

.root {
  display: flex;
}

.MuiInput-underline {
  border-bottom: solid 1px $primary_hex;
}

.ag-theme-alpine {
    --ag-background-color: transparent !important;
    --ag-odd-row-background-color: transparent !important;
    --ag-selected-row-background-color: rgba(61,61,61,0.3) !important;
    --ag-row-hover-color: rgba(188,188,188,0.3) !important;
    --ag-secondary-border-color: #ddd !important;
}

.MuiDialogTitle-root {
    color: $primary_hex;
}

.MuiPaper-root {
    border: solid 1px $primary_hex;
}

.mantine-AppShell-main {
    min-height: 0px;
    padding-top: 0px;
}

.mantine-Header-root {
    display: none;
}

.mantine-Modal-header {
    padding: 0px;
    padding-right: 5px;
    padding-top: 5px;
}

.mantine-Modal-inner {
    padding-left: 0px;
    z-index: 202 !important;
}

.mantine-TextInput-input {
    #border: none;
    #border-bottom: solid 1px $primary_hex;
    #padding: 0px;
}

.mantine-Radio-label {
    padding-left: 0.3rem;
}

.mantine-Dialog-closeButton {
    top: 5px;
    right: 5px;
}

.mantine-Navbar-root .mantine-Text-root {
    background-color: rgba(188,188,188,0.3);
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    text-align: center;
    border-left: solid 13px #5a5a5a;
}

.mantine-Navbar-root .mantine-Group-root {
    margin-left: -48px;
}

.mantine-ActionIcon-root {
    color: #000;
}

.mantine-ActionIcon-root:hover {
    background-color: rgba(254,254,254,0.5) !important;
}

.mantine-Switch-track {
    background-color: #000 !important;
    border-color: #000 !important;
    color: #fff !important;
    font-size: 10px;
}

.mantine-Modal-overlay {
    z-index: 201 !important;
}

.mantine-NumberInput-label {
    word-break: inherit !important;
}

.mantine-NumberInput-input {
    padding-left: 3px !important;
}

@media (min-width: 48em) {
    .mantine-Navbar-root {
        width: 7.5%;
        min-width: 7.5%;
    }
}

.chrome-picker {
    width: 145px !important;
}

.flexbox-fix input {
    background-color: #fff;
}

.topBar {
    box-shadow: none !important;
}

.raceCandBox {
    margin-top: -7px;
}

.yearInput label {
  font-weight: bold;
  font-size: 15px;
}

.yearInput input {
  font-weight: bold;
}

.mantine-Carousel-viewport {
  height: 100% !important;
}

.mantine-Image-root {
  height: 100% !important;
}

.mantine-InputWrapper-label {
  white-space: nowrap;
}